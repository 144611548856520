// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-autoencoder-index-tsx": () => import("./../../../src/pages/autoencoder/index.tsx" /* webpackChunkName: "component---src-pages-autoencoder-index-tsx" */),
  "component---src-pages-connect-4-index-tsx": () => import("./../../../src/pages/connect4/index.tsx" /* webpackChunkName: "component---src-pages-connect-4-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-simple-weather-index-tsx": () => import("./../../../src/pages/simple-weather/index.tsx" /* webpackChunkName: "component---src-pages-simple-weather-index-tsx" */),
  "component---src-pages-tic-tac-toe-index-tsx": () => import("./../../../src/pages/tic-tac-toe/index.tsx" /* webpackChunkName: "component---src-pages-tic-tac-toe-index-tsx" */),
  "component---src-pages-tsp-index-tsx": () => import("./../../../src/pages/tsp/index.tsx" /* webpackChunkName: "component---src-pages-tsp-index-tsx" */)
}

